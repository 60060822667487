import ahoy from 'ahoy.js'
import Alpine from 'alpinejs'
import * as Rails from '@rails/ujs'

window.ahoy = ahoy
window.Alpine = Alpine
window.Rails = Rails

import '~/controllers'
import '../src/libs/components.js'

document.addEventListener("DOMContentLoaded", () => {
  Alpine.start()
  if (window._rails_loaded == undefined)
    Rails.start()
});