import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["clearValue", "hideable"]

  connect() {
    const selectedRadio = document.querySelector('input[type="radio"]:checked')
    if (selectedRadio) {
      selectedRadio.dispatchEvent(new Event("change"))
    }
  }

  toggleTargets(e) {
    const elements = document.querySelectorAll('[data-visibility]')

    // Iterate through the selected elements and add the 'hidden' class
    elements.forEach(element => {
      element.classList.add('hidden');

    })

    if (e.target.value.includes("External")) {
      this.hideableTargets.forEach((el) => {
        el.classList.remove("hidden");
      });
    }
    else {
      this.hideableTargets.forEach((el) => {
        el.classList.add("hidden");
      });
    }

    if (['sfhq', 'vhq', 'lighthouse'].includes(e.target.value)) {
      elements.forEach(element => {
        if (element.dataset.visibility.includes(e.target.value)) {
          element.classList.remove('hidden')
        }
      })
    }

    document.querySelectorAll('.hidden input').forEach(element => {
      if (element.type == 'checkbox')
        element.checked = false
      else
        element.value = ''
    })
  }
}
